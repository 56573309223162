<template>
    <section class="main-content-section">
        <div class="grid grid-pad">
            <div class="col-1-1 center">
                <h2 class="page-title">{{thankYou}}</h2>
            </div>
            <div class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="textpage">
                    <p>{{yourOrderIsConfirmed}}</p>
                    <div class="flex justify-center btn-container">
                        <router-link :to="`/service/search-order?orderId=${this.$route.query.orderId}`" class="btn btn-primary alternative">{{viewMyOrder}}</router-link> 
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ThankYou',
    
    computed: {
        ...mapGetters([
            'metaTitle',
            // Texts
            'thankYou', 
            'yourOrderIsConfirmed', 
            'viewMyOrder' 
        ])
    },

    created() {
        this.setDocumentTitle();
    },

    updated() {
        this.setDocumentTitle();
    },

    methods: {
        setDocumentTitle() {
            document.title = this.thankYou + this.metaTitle;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.textpage {
    border-radius: 8px;
    box-shadow: $shadow-1;
    padding: 6rem;
    background: $white;
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 300;
    
    p {
        margin: 0 0 2rem 0;
    }
    
    p a,
    li a {
        display: inline-block;
        color: inherit;
        text-decoration: underline;
    }

    &-title {
        font-size: 2.8rem;
        font-weight: 300;
        line-height: normal;
        display: block;
        text-align: center;
        margin: 0 0 2rem 0;
    }
}

.btn-container {
    margin-top: 7rem;
}

a.btn {
    color: $white !important;
    text-decoration: none !important;
}

@media only screen and (max-width: 400px) {
    .textpage {
        padding: 6rem 2rem;
    }
}
</style>